import CompanyM_Logo from "assets/svgs/MLogo"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col, Container, Card } from "react-bootstrap"
import * as styles from "./styles.module.scss"
const WorkWithUs = () => {
	return (
		<>
			<Container>
				<h2 className={styles.Workus}>WORK WITH US</h2>
				<Row className={styles.projectrow}>
					<Col sm={12} md={6}>
						<p className={styles.Workgraph1}>
							{" "}
							We are covering better ways of developing software by doing it and helping others doing it. Through this
							work we have come to value: Individuals and interactions over process and tools, Working software over
							comprehensive documentation, Customer collaboration over contract negotiation, Responding to change over
							following a plan. That is, where there is value in the items on the right, we value the items on the left
							more.
						</p>
					</Col>
					<Col sm={12} md={6}>
						<div>
							<StaticImage
								className="workUsImage"
								placeholder="none"
								src="../../../assets/svgs/secondTwo.svg"
								alt="Welcome Image"
							/>
						</div>
					</Col>
				</Row>

				<div className={styles.btnDiv}>
					<button
						className={styles.Viewbutton1}
						variant="primary"
						type="submit"
						onClick={() => {
							navigate("/contactus")
						}}
					>
						CONTACT US
					</button>
				</div>
			</Container>
		</>
	)
}
export default WorkWithUs
