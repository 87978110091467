import React from "react"
import * as styles from "./styles.module.scss"
import { Card, Container, Row, Col } from "react-bootstrap"
import { navigate } from "gatsby"
import AOS from "aos"
import "aos/dist/aos.css"
import { HomePage } from "../../../constants/index"
import { Button } from "antd"

const Servicepage = () => {
	// const width = 50

	const technologies = HomePage.technologies
	const services = HomePage.services

	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
	}
	function cardClickHandler(techId) {
		localStorage.setItem("activePage", 2)
		navigate(`/services/#${techId}`)
	}

	return (
		<>
			<div className={styles.serve}>
				<Container>
					<Row>
						{services.map((text, index) => {
							return (
								<Col key={index}>
									<h2 className={styles.services}>{text.heading}</h2>
									<p className={styles.technology}>{text.subheading}</p>
									<p className={styles.map}> {text.detail}</p>
								</Col>
							)
						})}
					</Row>
				</Container>
			</div>
			{/* <Container> */}
			<Row className={`m-0 ${styles.mainSblock}`} xs={2} sm={3} md={4} lg={5}>
				{technologies.map((technology, index) => {
					const clr = `${technology}`
					return (
						<Col
							key={index}
							lg={2}
							md={3}
							sm={4}
							xs={6}
							className={styles.cardpadding}
							data-aos="fade-up"
							data-aos-once="true"
							data-aos-offset="100"
							data-aos-easing="ease-in-out"
							data-aos-duration="1000"
							data-aos-mirror="true"
						>
							<Card
								className={`${styles.card} ${styles.card0}`}
								onClick={() => {
									cardClickHandler("WebDev")
								}}
								style={{ backgroundColor: `${technology.color}`, height: "264px" }}
							>
								{technology.icon}
								<Card.Body>
									<Card.Text className={styles.cardText}>{technology.title}</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					)
				})}
			</Row>
			{/* </Container> */}
			<div className={styles.btnSDiv}>
				<button
					className={styles.ViewSbutton1}
					variant="primary"
					type="submit"
					onClick={() => {
						navigate("/contactus")
					}}
				>
					CONTACT US
				</button>
			</div>
		</>
	)
}
export default Servicepage
