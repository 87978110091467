// extracted by mini-css-extract-plugin
export var JobTitle = "styles-module--JobTitle--u4JmK";
export var JobViewBtn = "styles-module--JobViewBtn--Kh7Pc";
export var Mcolor = "styles-module--Mcolor--F5Up9";
export var Viewbutton1 = "styles-module--Viewbutton1--KVbL-";
export var Workgraph1 = "styles-module--Workgraph1--2DwQO";
export var Workus = "styles-module--Workus--eJI4r";
export var btnDiv = "styles-module--btnDiv--t8YQr";
export var custome_div = "styles-module--custome_div--Cy9mp";
export var icJobView = "styles-module--icJobView--a-ezd";
export var jobsCard = "styles-module--jobsCard--iudR3";
export var primary = "\"abc\"";
export var projectrow = "styles-module--projectrow--5eHKA";
export var secondary = "\"dec\"";
export var workUsImage = "styles-module--workUsImage--DFjLJ";