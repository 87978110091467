import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const StaticImagee = () => {
	return (
		<>
			<div className="pt-5 mt-5">
				<StaticImage src="../../../assets/images/TrustYourMillonDollorIdea.png" alt="hero image" />
			</div>
		</>
	)
}

export default StaticImagee
