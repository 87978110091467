// extracted by mini-css-extract-plugin
export var Choosemain = "styles-module--Choosemain--aguwp";
export var choose = "styles-module--choose--tMpZe";
export var col1 = "styles-module--col1--W+ltp";
export var col2Choose = "styles-module--col2Choose--Gd+QP";
export var convert = "styles-module--convert--r2xMn";
export var paragraph1 = "styles-module--paragraph1--87zg8";
export var paragraph2 = "styles-module--paragraph2--WyxAL";
export var primary = "\"abc\"";
export var rowChoose = "styles-module--rowChoose--t0EdR";
export var secondary = "\"dec\"";