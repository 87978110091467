import AOS from "aos"
import "aos/dist/aos.css"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Row } from "react-bootstrap"
import { HomePage } from "../../../constants/index"
import * as styles from "./styles.module.scss"
import React from "react"
import InfoBlock from "constants/companyInfo"

const ChooseUspage = () => {
	const chooseus = HomePage.chooseUs

	if (typeof window !== "undefined") {
		window.addEventListener("load", AOS.refresh())
		AOS.init({
			once: true,
			mirror: true,
		})
	}
	return (
		<>
			<div className={`m-0 px-3 ${styles.Choosemain}`}>
				<div>
					<Row
						className={styles.rowChoose}
						data-aos="fade-right"
						data-aos-once="true"
						data-aos-offset="100"
						data-aos-easing="ease-in-out"
						data-aos-duration="1500"
						data-aos-mirror="true"
					>
						{chooseus.map((choice, index) => {
							return (
								<Col key={index} md={6} sm={12} className={styles.col1}>
									<h3 className={styles.convert}> {choice.title} </h3>
									<h1 className={styles.choose}>{choice.heading}</h1>
									<p className={styles.paragraph1}>
										{InfoBlock.Company}
										{choice.detail}
									</p>
									<p className={styles.paragraph2}>{choice.detail2}</p>
								</Col>
							)
						})}
						<Col md={6} sm={12} className={styles.col2Choose}>
							<StaticImage
								className={styles.projectpicss}
								placeholder="none"
								alt="chooseUs img"
								src="../../../assets/images/WhyChooseUsTwo.png"
							></StaticImage>
						</Col>
					</Row>
				</div>
			</div>
		</>
	)
}
export default ChooseUspage
