import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import { HomePage } from "../../../constants/index"
const Customepage = () => {
	const custom = HomePage.custom

	return (
		<>
			{/* <div className="container"> */}
			<div className={styles.custome_div}>
				<Row className={`m-0 ${styles.projectrow}`}>
					{custom.map((custom, index) => {
						return (
							<Col key={index} md={12}>
								<h2 className={styles.project}>{custom.title}</h2>
								<h2 className={styles.soft}>{custom.heading}</h2>
								<p className={styles.quality}>{custom.detail}</p>
							</Col>
						)
					})}
					<Col md={12} className="p-0">
						<Row className="m-0 p-0">
							<Col lg={3} sm={6} className="p-0">
								<Row className="m-0 p-0">
									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage} ${styles.ImgContainer1}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment.png"
												placeholder="none"
												layout="fixed"
												alt="mobile3 img"
												height={200}
											></StaticImage>
										</div>
									</Col>

									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage1} ${styles.ImgContainer1}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment2.png"
												placeholder="none"
												layout="fixed"
												alt="mobile2 img"
												height={200}
											></StaticImage>
										</div>
									</Col>
								</Row>
							</Col>
							<Col lg={3} sm={6} className="p-0">
								<Row className="m-0 p-0">
									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage2} ${styles.ImgContainer1}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment3.png"
												placeholder="none"
												layout="fixed"
												height={200}
												alt="mobile3"
											></StaticImage>
										</div>
									</Col>
									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage3} ${styles.ImgContainer1}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment4.png"
												placeholder="none"
												layout="fixed"
												alt="DriverMouse img"
												height={200}
											></StaticImage>
										</div>
									</Col>
								</Row>
							</Col>
							<Col lg={3} sm={6} className="p-0">
								<Row className="m-0 p-0">
									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage4} ${styles.ImgContainer2}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment5.png"
												placeholder="none"
												layout="fixed"
												alt="DriverMouse img"
												height={400}
											></StaticImage>
										</div>
									</Col>
								</Row>
							</Col>
							<Col lg={3} sm={6} className="p-0">
								<Row className="m-0 p-0">
									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage5} ${styles.ImgContainer1}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment6.png"
												placeholder="none"
												layout="fixed"
												alt="mobile3img"
												height={200}
											></StaticImage>
										</div>
									</Col>
									<Col md={12} className="p-0">
										<div className={`${styles.customProjectImage6} ${styles.ImgContainer1}`}>
											<StaticImage
												className={styles.ImgForScale}
												src="../../../assets/images/CustomSoftwareDevelopment7.png"
												placeholder="none"
												layout="fixed"
												alt="mobile3 img"
												height={200}
											></StaticImage>
										</div>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
			{/* </div> */}
		</>
	)
}
export default Customepage
