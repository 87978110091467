import React, { useEffect } from "react"
import { Container } from "react-bootstrap"
import Welcome from "components/pages/welcome"
import Servicepage from "components/pages/serve"
import ChooseUspage from "components/pages/ChooseUs"
import Customepage from "components/pages/Custome"
import "./styles.scss"
import { StaticImage } from "gatsby-plugin-image"
import HeaderTwo from "components/shared/HeaderTwo"
import Footer from "components/shared/Footer"
import AOS from "aos"
import "aos/dist/aos.css"
import { Helmet } from "react-helmet"
import WorkWithUs from "components/pages/WorkWithus"
import StaticImagee from "components/pages/StaticImage"

const Home = () => {
	if (typeof window !== "undefined") {
		AOS.init({
			once: true,
			mirror: true,
		})
		window.addEventListener("load", AOS.refresh())
		localStorage.setItem("activePage", 0)
	}
	useEffect(() => {
		const position = window.pageYOffset
	}, [])

	return (
		<>
			<Helmet>
				<title> Refstal Solutions | Home</title>
			</Helmet>
			<HeaderTwo />
			<Container>
				<div className=" mt-5 row rowHero">
					<StaticImagee />
				</div>
			</Container>
			<Welcome />
			<Servicepage />
			<ChooseUspage />
			<Customepage />
			<WorkWithUs />
			<div className="display" style={{ position: "relative" }}>
				<div
					style={{ position: "absolute", bottom: "0", right: "0px", width: "210px", zIndex: "-100" }}
					data-aos="fade-up"
					data-aos-once="true"
					data-aos-offset="100"
					data-aos-easing="ease-in-out"
					data-aos-duration="1000"
					data-aos-mirror="true"
				>
					<StaticImage
						src="../assets/svgs/WORKUSTwo.svg"
						alt="Work With Us"
						style={{ width: "70%", height: "70%", display: "flex", justifyContent: "space-evenly" }}
					/>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Home
